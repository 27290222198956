@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon/icomoon.eot?9531od');
  src:  url('../fonts/icomoon/icomoon.eot?9531od#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?9531od') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?9531od') format('woff'),
    url('../fonts/icomoon/icomoon.svg?9531od#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bank:before {
  content: "\e950";
}
.icon-check1:before {
  content: "\e94e";
}
.icon-service-main:before {
  content: "\e94a";
}
.icon-skill-main:before {
  content: "\e94b";
}
.icon-products .path1:before {
  content: "\e938";
  color: rgb(200, 200, 200);
}
.icon-products .path2:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-software .path1:before {
  content: "\e943";
  color: rgb(200, 200, 200);
}
.icon-software .path2:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-software .path3:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(220, 223, 226);
}
.icon-software .path4:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(202, 208, 216);
}
.icon-software .path5:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(233, 233, 233);
}
.icon-units .path1:before {
  content: "\e948";
  color: rgb(200, 200, 200);
}
.icon-units .path2:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-skills:before {
  content: "\e900";
  color: #c2c2c2;
}
.icon-bars:before {
  content: "\e901";
}
.icon-excel:before {
  content: "\e902";
}
.icon-Icon:before {
  content: "\e903";
}
.icon-Category:before {
  content: "\e904";
}
.icon-Document:before {
  content: "\e905";
}
.icon-Filter:before {
  content: "\e906";
}
.icon-edit:before {
  content: "\e907";
}
.icon-Info-Circle:before {
  content: "\e908";
}
.icon-Lock:before {
  content: "\e909";
}
.icon-Search:before {
  content: "\e90a";
}
.icon-add:before {
  content: "\e90b";
}
.icon-cheveron-down:before {
  content: "\e90c";
}
.icon-cheveron-left:before {
  content: "\e90d";
}
.icon-bell:before {
  content: "\e90e";
}
.icon-Chat:before {
  content: "\e90f";
}
.icon-check:before {
  content: "\e910";
}
.icon-cloud-computing:before {
  content: "\e911";
}
.icon-Delete:before {
  content: "\e912";
}
.icon-cheveron-right:before {
  content: "\e913";
}
.icon-Logout:before {
  content: "\e914";
}
.icon-Profile:before {
  content: "\e915";
}
.icon-close-solid:before {
  content: "\e916";
}
.icon-tick:before {
  content: "\e917";
}
.icon-menu:before {
  content: "\e918";
}
.icon-steper_certificate:before {
  content: "\e919";
}
.icon-steper_job:before {
  content: "\e91a";
}
.icon-steper_lanquage:before {
  content: "\e91b";
}
.icon-steper_maharat:before {
  content: "\e91c";
}
.icon-steper_software:before {
  content: "\e91d";
}
.icon-steper_tahsil:before {
  content: "\e91e";
}
.icon-steper_upload:before {
  content: "\e91f";
}
.icon-steper_user:before {
  content: "\e920";
}
.icon-steper_users:before {
  content: "\e921";
}
.icon-cheveron-up:before {
  content: "\e922";
}
.icon-Iconly-Bulk-Info-Square:before {
  content: "\e923";
}
.icon-uniE923:before {
  content: "\e924";
}
.icon-uniE924:before {
  content: "\e925";
}
.icon-Equipment-and-production:before {
  content: "\e926";
}
.icon-services:before {
  content: "\e927";
}
.icon-Technical-staff:before {
  content: "\e928";
}
.icon-Financial:before {
  content: "\e929";
}
.icon-managers:before {
  content: "\e92a";
}
.icon-add_paper:before {
  content: "\e92b";
}
.icon-Articles:before {
  content: "\e92c";
}
.icon-delete:before {
  content: "\e92d";
}
.icon-user2:before {
  content: "\e92e";
}
.icon-linkedin:before {
  content: "\e92f";
}
.icon-Notification:before {
  content: "\e930";
}
.icon-setting:before {
  content: "\e931";
}
.icon-softwares_chart:before {
  content: "\e932";
}
.icon-star-full:before {
  content: "\e933";
}
.icon-user-circle-o:before {
  content: "\e934";
}
.icon-users:before {
  content: "\e935";
}
.icon-uniE936:before {
  content: "\e936";
}
.icon-uniE937:before {
  content: "\e937";
}
.icon-uniE93A:before {
  content: "\e93a";
}
.icon-uniE93B:before {
  content: "\e93b";
}
.icon-uniE93C:before {
  content: "\e93c";
}
.icon-uniE93D:before {
  content: "\e93d";
}
.icon-uniE93E:before {
  content: "\e93e";
}
.icon-uniE93F:before {
  content: "\e93f";
}
.icon-uniE940:before {
  content: "\e940";
}
.icon-uniE941:before {
  content: "\e941";
}
.icon-uniE942:before {
  content: "\e942";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-images:before {
  content: "\e94c";
}
.icon-blog:before {
  content: "\e94d";
}
.icon-phone:before {
  content: "\e94f";
}
.icon-bubbles:before {
  content: "\e96c";
}
.icon-user-tie:before {
  content: "\e976";
}
.icon-lab:before {
  content: "\e9aa";
}
.icon-mail:before {
  content: "\ea83";
}
