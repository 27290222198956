@import "variable";

.dv-factor-page {
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgb(204, 204, 204);
  z-index: 9999;


  .dv-factor-content {
    overflow-y: auto;
    padding: 2em;

    .w-50 {
      width: 100%;
    }

    .dv-factor-title {
      font-size: 14px;
      line-height: 24px;
      color: #3C3C3C;
    }

    .dv-factor-value {
      font-size: 16px;
      line-height: 24px;
      color: #3C3C3C;
      font-family: iranYekanBold;
    }

    .dv-factor-table {
      tr {
        //border: 1px solid #000;
        font-size: 14px !important;

        th, td {
          border: 1px solid #000;
          min-width: 130px;
          text-align: center;
          padding: 12px 10px;

          &.dv-count-td {
            min-width: 50px;
            max-width: 50px;
            width: 50px;
          }
        }

        td {
          padding: 8px 10px;
        }

        &.dv-mini-table-fsize {

        }

      }
    }

    .dv-print-factor-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $main-red;
      border-radius: 10px;
      color: #fff;
      padding: 7px 40px;
      border: none;
    }
  }
}

page {
  background: white;
  display: block;
  margin: 2cm auto;
  border: 5px solid $main-red;
  box-shadow: 0 0 0.25cm rgba(0, 0, 0, 0.3);
  outline: 25px solid #fff;
}

page.dv-a4 {
  width: 21cm;
  //height: 29.7cm;
}

page.dv-a4.dv-landscape {
  width: 29.7cm;
  height: 21cm;
}

.dv-default-text {
  color: $main-red;
}

.user-info-card-title , .user-info-card-large-33 .user-info-card-value {
  width: 40%;
}

.user-info-card-value , .user-info-card-large-33 .user-info-card-title {
  width: 60%;
}

.user-info-card-100 .user-info-card-title, .user-info-card-large .user-info-card-value {
  width: 20%;
}

.user-info-card-100 .user-info-card-value, .user-info-card-large .user-info-card-title {
  width: 80%;
}

.dv-table-with-image {
  width: calc(100% - 155px);
}

.user-info-card-title, .user-info-card-value {
  border: 1px solid #DDE1E6;
  padding: 4px 7px;
  font-size: 12px;
  color: #2F4858;
}

.dv-personal-image {
  width: 155px;
  height: 160px;
  border: 1px dashed $main-red;
  border-radius: 8px;
  overflow: hidden;

  img {
    border-radius: 8px;
  }
}

.w-33{
    width: 33.33%;
}

.dv-center-padding {
  padding: 16px;
  page-break-after: always;
  background: white;
  display: block;
  margin: 1cm 0;
}

.dv-resume-parent{
  position: fixed;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  z-index: 9999999999999999;
  top: 0;
  left: 0;
  background: #e7e7e7;
}

.dv-resume-table{
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #DDE1E6;
    font-size: 14px;
    color: #2F4858;
    margin-bottom: 10px;
    tr {
        border: 1px solid #DDE1E6;
        padding: 5px 7px;
    }
}

.dv-resume-table tr th {
  padding: 5px !important;
}

@media print {

  .dv-resume-parent{
    margin-top: -4cm;
    position: unset;
    top: unset;
    left: unset;
    background: unset;
  }

  page {
    border: 0;
  }

  .afa-dashboard-header, .afa-sidebar-logo-menu {
    display: none !important;
  }

  //@page {
  //size: 21cm 29.7cm;
  //margin: 25mm;
  //}

  html, body {
    width: 100%;
    min-height: max-content !important;
    overflow: visible !important;
    display: block;
  }
  .dv-table-with-image {
    width: calc(100% - 125px);
  }
  .dv-personal-image {
    width: 125px;
  }

  .dv-factor-content {
    padding: 0;
  }
  //
  page.dv-a4 {
    width: 18.5cm;
    //height: 26cm;
  }
  //
  .dv-center-padding {
    padding: 4px 0.5em;
    border: 4px solid $main-red;
    height: 24cm;


    .my-5 {
      margin-top: 1em !important;
      margin-bottom: 1em !important;
    }
  }

  .dv-min-height-resume-table{
    min-height: 10cm;
  }
  .dv-min-height-resume-table3{
    min-height: 6cm;
  }
}